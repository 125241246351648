<!--
=========================================================
* sketch.media - crm 2.0 - v1.1.2
=========================================================

* Product Page: https://sketch.media
* Copyright 2023 sketch.media GmbH (https://www.sketch.media)

Coded by sketch.media GmbH

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <div id="wrapper" class="d-flex wrapper open" :class="domain" v-if="$store.state.configLoader.loadPercent === 0">
    <sidenav v-if="this.$route.name !== 'Signin'"/>
    <main class="main-content">
      <navbar v-if="this.$route.name !== 'Signin'"/>
      <div class="appContainer">
        <div class="mainWrap">
          <router-view/>
        </div>
      </div>
    </main>
  </div>
  <div id="preloader" v-else>
    <div class="loader">
      <div class="loadbarwrap">
        <div class="loadbar" :style="'width:'+this.$store.state.configLoader.loadPercent+'%;'"></div>
      </div>
      <div class="loader__figure" style="margin-bottom: 24px;">{{ this.$store.state.configLoader.loadPercent }} %</div>
      <p class="loader__message">{{ this.$store.state.configLoader.loadMessage }}</p>
    </div>
  </div>
</template>
<script>
import Sidenav from "./sublayouts/Sidenav";
import Navbar from "@/sublayouts/Navbars/Navbar.vue";
import {mapMutations, mapActions, mapGetters} from "vuex";
import axios from "axios";
import {appInstance} from "@/main";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
  },
  data() {
    return {
      domain: null,
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator"]),
    ...mapActions(["getPlugins", "getEmployees", "checkToken"]),
  },
  computed: {
    ...mapGetters(["getUser", "getConfigs", "getApiUrl"])
  },
  created() {

    axios.defaults.cache = 'no-store';
    this.checkToken();
    this.$store.commit("createStorage");
  },
  beforeMount() {
    // Custom $t method
    if (localStorage.getItem("sketchManagerToken")) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "checkMenu", {headers}).then((r) => {
        this.$store.commit("setMenu", r.data.result.data);
      }).catch((error) => {
        this.$store.dispatch("handleApiResponse", error.response);
        this.$store.commit("logout");
      });
    } else {
      this.$store.commit("logout", false);
    }
  },
  mounted() {
    if (this.getApiUrl) {
      let url = this.getApiUrl;
      // whihout https://
      url = url.replaceAll("https://", "");
      url = url.replaceAll("-", "_");
      url = url.replaceAll(".de", "");
      url = url.replaceAll(".", "_");
      url = url.replaceAll("/", "");
      if (this.$route && this.$route.name) {
        this.domain = url + " " + this.$route.name;
      } else {
        this.domain = url;
      }
    }
  },
  emits: ["noticeAdded"],
  watch: {
    $route(to, from) {
      if (this.getApiUrl) {
        let url = this.getApiUrl;
        // whihout https://
        url = url.replaceAll("https://", "");
        url = url.replaceAll("-", "_");
        url = url.replaceAll(".de", "");
        url = url.replaceAll(".", "_");
        url = url.replaceAll("/", "");
        if (this.$route && this.$route.name) {
          this.domain = url + " " + this.$route.name;
        } else {
          this.domain = url;
        }
      }
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import "./assets/scss/styles.scss";

.Vue-Toastification__container {
  z-index: 9999999 !important;
}

embed#flipable {
  max-width: 100%;
  height: auto;
}

.spanner_api_sketch_connect {
}
</style>
