<template>
  <VueMultiselect

    :options="customers"
    :multiple="false"
    :internal-search="true"
    :close-on-select="true"
    :show-labels="false"
    @search-change="debouncedFetchOptions"
    valueProp="id"
    class="mb-3"
    :class="(small) ? 'small' : ''"
    :label="'full_name'"
    locale="de"
    :placeholder="'Kunden suchen'"
    :showNoOptions="false"
    :noOptionsText="'Kunden suchen'"
    :noResultsText="'Kunden suchen'"
    :minChars="3"
    :filterResults="false"
    v-model="returnCustomer"
  />
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import axios from 'axios';
import {mapGetters} from 'vuex';

export default {
  name: "customersearch.vue",
  emits: ['changed-customer'],
  props:{
    small: {
      type: Boolean,
      default: false
    },
  },
  components: {
    VueMultiselect
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
  },
  data() {
    return {
      customers: [ ],
      returnCustomer: {},
    };
  },
  methods:{
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
    fetchOptions(searchTerm) {
      if (searchTerm.length >= 3) {

        let headers = {
          'crmAuth': localStorage.getItem('sketchManagerToken')
        };
        // prepare searchterm, strtolower and urlencode
        searchTerm = searchTerm.toLowerCase();
        searchTerm = encodeURIComponent(searchTerm);
        axios.get(this.$store.state.apiUrl + "customer/search?limit=0&quick=true&filter_search=" + searchTerm, {headers}).then((response) => {
            this.customers = response.data.result.data;
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        // Clear the options when the search term is less than 3 characters
        this.customers = [];
      }
    }
  },
  watch: {
    returnCustomer(newVal, oldVal) {
      // Emit the entire selected customer object to the parent
      this.$emit('changed-customer', newVal);
    }
  },
  created(){
    this.debouncedFetchOptions = this.debounce(this.fetchOptions, 300);
  }

}
</script>
<style scoped>
</style>
