<template>
  <template v-if="modal">
    <div class="modal-header" v-if="customerActive">
      <i class="fa-duotone fa-document"></i> Dokument erstellen für
      <template v-if="customerActive.c_company ">{{ customerActive.c_company }} -</template>
      {{ customerActive.c_firstname }} {{ customerActive.c_lastname }}

      <button type="button" class="btn btn-outline-primary" style="margin-left: auto;" @click="updateDocument(true)"><i
        class="fa-duotone fa-floppy-disk"></i> Speichern
      </button>
      <button v-if="modal" type="button" class="btn-close float-end" style="margin-left: 24px;"
              @click='$store.commit("closeModal", "document");'></button>
    </div>
  </template>
  <ul class="tabNav">
    <template v-if="modal">
      <template v-for="tab in orderTabs">
        <template v-if="documentCopy && documentCopy.id">
          <li @click="activeOrderTab =tab.name;"
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
        <template v-else>
          <li @click="activeOrderTab =tab.name"
              v-if="(isContract || tab.name !== 'contract') && tab.name !== 'preview'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
      </template>
    </template>
    <template v-else>
      <template v-for="tab in orderTabs">
        <template v-if="documentCopy && documentCopy.id">
          <li @click="$router.push({name:'DocumentsTabs', params:{id:documentCopy.id,tab:tab.name}}) "
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
        <template v-else>
          <li @click="$router.push({name:'DocumentsTabs', params:{id:'new',tab:tab.name}}) "
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
      </template>
    </template>

  </ul>
  <div class="card">
    <h6 class="card-header">
      <template v-if="modal">
        <i class="fa-duotone fa-document"></i> Dokument erstellen für
        <template v-if="customerActive.c_company ">{{ customerActive.c_company }} -</template>
        {{ customerActive.c_firstname }} {{ customerActive.c_lastname }}
      </template>
      <template v-else>
        <i class="fa-duotone fa-file"></i> Dokument bearbeiten
      </template>
      <template v-if="activeOrderTab === 'booking'">
        <div class="dropdown d-inline-block ml-auto" :class="(modal) ? 'float-end' :''">
          <button class="btn btn-sm ms-3 dropdown-toggle btn-outline-primary" id="customerDocActions" type="button"
                  :class="(showFolderDropdown) ? 'active' : ''"
                  data-toggle="dropdown" aria-expanded="false" @click="toggleDropdown()">
            <i class="fa-duotone fa-folder"></i> Order hinzufügen
          </button>
          <ul class="dropdown-menu" aria-labelledby="customerDocActions" v-if="showFolderDropdown">
            <li>
              <button class="dropdown-item" @click='addFolder("spacer")'><i
                class="fa-duotone fa-file-dashed-line text-primary"></i>
                Trenner
              </button>
            </li>
            <!--          <li>-->
            <!--            <button class="dropdown-item" @click="addFolder('required')"><i-->
            <!--              class="fa-duotone fa-ballot-check text-danger"></i> Pflicht-Auswahl-->
            <!--            </button>-->
            <!--          </li>-->
            <!--          <li>-->
            <!--            <button class="dropdown-item" @click="addFolder('optional')"><i-->
            <!--              class="fa-duotone fa-square-dashed-circle-plus text-success"></i> Zusatzoptionen-->
            <!--            </button>-->
            <!--          </li>-->
          </ul>
        </div>
        <button type="button" class="btn btn-outline-primary btn-sm ms-3 " style="margin-left: auto;"
                @click="toggleList" :class="(modal) ? 'float-end' :''">
          <i
            class="fa-duotone fa-tag"></i> Produkt hinzufügen
        </button>
        <div class="btn-group me-3 ms-3" v-if="!modal">
          <button type="button" class="btn btn-outline-primary" style="margin-left: auto;" @click="updateDocument(true)"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern und Schließen
          </button>
          <button type="button" class="btn btn-outline-primary btn-sm "
                  @click="updateDocument(false)"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
      </template>
      <template v-else>
      <div class="btn-group me-3 ml-auto" v-if="!modal">
        <button type="button" class="btn btn-outline-primary" style="margin-left: auto;" @click="updateDocument(true)"><i
          class="fa-duotone fa-floppy-disk"></i> Speichern und Schließen
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm "
                @click="updateDocument(false)"><i
          class="fa-duotone fa-floppy-disk"></i> Speichern
        </button>
      </div></template>
    </h6>
    <div class="card-body documentItem">
      <div class="docOutestWrap">
        <template v-if="customerActive && Object.keys(customerActive).length === 0 && modal">
          <div class="form-group">
            <label>Kunde wählen</label>
            <customersearch v-model="customer"/>
          </div>
        </template>
        <template v-else>
          <div class="tab-pane active" v-if="activeOrderTab === 'base'"
               aria-labelledby="pills-home-tab">
            <div class="row">
              <div class="col-lg-4 col-12">
                <div class="form-group">
                  <label>Dokument-Typ</label>

                  <select class="form-select" v-model="documentCopy.d_catid">
                    <option value="">- Bitte wählen -</option>
                    <option v-for="opt in getConfigs.categories_documents" :value="opt.id">{{ opt.title }}</option>
                  </select>

                </div>
                <div class="form-group">
                  <label>Modus</label>
                  <div class="btn-group btn-group-yesno ms-3" role="group">
                    <input type="radio" class="btn-check" id="active_mode_0" value="active"
                           v-model="documentCopy.active_mode">
                    <label class="btn btn-outline-success" for="active_mode_0">Aktiv</label>
                    <input type="radio" class="btn-check" id="active_mode_1" value="prepare"
                           v-model="documentCopy.active_mode">
                    <label class="btn btn-outline-secondary" for="active_mode_1">Passiv</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-12">
                <div class="form-group">
                  <label>Untertitel</label>
                  <textarea class="form-control" rows="3" v-model="documentCopy.info_field_1"></textarea>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Kundenadresse</label>
                  <select class="form-select" v-model="documentCopy.address_id">
                    <option value="0">- Hauptadresse -</option>
                    <option v-if="addresses"
                            v-for="a in addresses" :value="a.id">{{ a.c_company }} - {{ a.c_firstname }}
                      {{ a.c_lastname }}
                    </option>
                  </select>


                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Ersteller des Dokuments</label>
                  <select class="form-select" v-model="documentCopy.d_created_author">
                    <option v-for="employee in getEmployees" :value="employee.u_id">{{
                        employee.e_name
                      }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Rechnungs-Typ</label>
                  <div class="btn-group btn-group-yesno ms-3" role="group">
                    <input type="radio" class="btn-check" id="invoice_type_1" value="1"
                           v-model="documentCopy.invoice_type">
                    <label class="btn btn-outline-success" for="invoice_type_1">Standard</label>
                    <input type="radio" class="btn-check" id="invoice_type_2" value="2"
                           v-model="documentCopy.invoice_type">
                    <label class="btn btn-outline-danger" for="invoice_type_2">SEPA</label>
                    <input type="radio" class="btn-check" id="invoice_type_3" value="3"
                           v-model="documentCopy.invoice_type">
                    <label class="btn btn-outline-primary" for="invoice_type_3">Skonto</label>
                    <input type="radio" class="btn-check" id="invoice_type_4" value="4"
                           v-model="documentCopy.invoice_type">
                    <label class="btn btn-outline-secondary" for="invoice_type_4">Bereits beglichen</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Lieferdatum</label>
                      <VueDatePicker v-model="documentCopy.doc_dl_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Lieferdatum" :auto-apply="true" :close-on-auto-apply="true"
                                     locale="de"
                                     :enable-time-picker="false" cancel-text="X"
                                     :select-text="this.$t('glboals.choose')"
                                     :teleport="true">
                      </VueDatePicker>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Erstellungsdatum</label>
                      <VueDatePicker v-model="documentCopy.doc_d_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Erstellungsdatum" :auto-apply="true" :close-on-auto-apply="true"
                                     locale="de" :enable-time-picker="false" cancel-text="X"
                                     :select-text="this.$t('glboals.choose')" :teleport="true">
                      </VueDatePicker>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12"
                       v-if="documentCopy.invoice_type === '3' || documentCopy.invoice_type === '1'">
                    <div class="form-group">
                      <label>Zahlungsziel</label>
                      <VueDatePicker v-model="documentCopy.doc_pay_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Zahlungsziel" :auto-apply="true" :close-on-auto-apply="true"
                                     locale="de"
                                     :enable-time-picker="false" cancel-text="X"
                                     :select-text="this.$t('glboals.choose')"
                                     :teleport="true">
                      </VueDatePicker>
                    </div>
                  </div>
                </div>
                <template v-if="documentCopy.invoice_type === '3'">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label>Skonto-Frist</label>
                        <input type="number" v-model="documentCopy.cash_discount_days" class="form-control">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Konto-Wert in %</label>
                        <input type="number" v-model="documentCopy.cash_discount_value" class="form-control">
                      </div>
                    </div>
                  </div>
                </template>
                <div class="row">
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Netto / Brutto in Berechnung</label>
                      <div class="btn-group btn-group-yesno ms-3" role="group">
                        <input type="radio" class="btn-check" id="use_nettobrutto_0" value="netto"
                               v-model="documentCopy.use_nettobrutto">
                        <label class="btn btn-outline-success" for="use_nettobrutto_0">Netto</label>
                        <input type="radio" class="btn-check" id="use_nettobrutto_1" value="brutto"
                               v-model="documentCopy.use_nettobrutto">
                        <label class="btn btn-outline-secondary" for="use_nettobrutto_1">Brutto</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Netto / Brutto in der PDF</label>
                      <div class="btn-group btn-group-yesno ms-3" role="group">
                        <input type="radio" class="btn-check" id="show_nettobrutto_0" value="netto"
                               v-model="documentCopy.show_nettobrutto">
                        <label class="btn btn-outline-success" for="show_nettobrutto_0">Netto</label>
                        <input type="radio" class="btn-check" id="show_nettobrutto_1" value="brutto"
                               v-model="documentCopy.show_nettobrutto">
                        <label class="btn btn-outline-secondary" for="show_nettobrutto_1">Brutto</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Sortierung</label>
                      <div class="btn-group btn-group-yesno ms-3" role="group">
                        <input type="radio" class="btn-check" id="items_sorting_0" value="default"
                               v-model="documentCopy.items_sorting">
                        <label class="btn btn-outline-success" for="items_sorting_0">Standard</label>
                        <input type="radio" class="btn-check" id="items_sorting_1" value="alphabetic"
                               v-model="documentCopy.items_sorting">
                        <label class="btn btn-outline-secondary" for="items_sorting_1">Alphabetisch</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Dokumentennummer</label>
                      <input class="form-control" readonly v-model="documentCopy.doc_number">
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Kundenauftragsnummer</label>
                      <input class="form-control" v-model="documentCopy.ext_customer_id">
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="form-group">
                      <label>Prozess-ID</label>
                      <input class="form-control" readonly v-model="documentCopy.process_id">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Kundenstatus</label>
                  <div class="btn-group btn-group-yesno ms-3" role="group">
                    <input type="radio" class="btn-check" id="kundenstatus_0" value="1"
                           v-model="documentCopy.d_state">
                    <label class="btn btn-outline-success" for="kundenstatus_0">Veröffentlicht</label>
                    <input type="radio" class="btn-check" id="kundenstatus_1" value="0"
                           v-model="documentCopy.d_state">
                    <label class="btn btn-outline-secondary" for="kundenstatus_1">Versteckt</label>
                    <input type="radio" class="btn-check" id="kundenstatus_2" value="-2"
                           v-model="documentCopy.d_state">
                    <label class="btn btn-outline-danger" for="kundenstatus_2">Papierkorb</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Info-Feld (unten)</label>
                      <Editor2 v-model="documentCopy.info_field_2"/>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Interne Notizen</label>
                      <Editor2 v-model="documentCopy.internal_comment"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Dokument-Zugänglichkeit</label>
                  <div class="btn-group btn-group-yesno ms-3" role="group">
                    <input type="radio" class="btn-check" id="special_owner_0" value="0"
                           v-model="documentCopy.special_owner">
                    <label class="btn btn-outline-success" for="special_owner_0">Für alle sichtbar (Standard)</label>
                    <input type="radio" class="btn-check" id="special_owner_1" value="1"
                           v-model="documentCopy.special_owner">
                    <label class="btn btn-outline-secondary" for="special_owner_1">Nur für mich sichtbar</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'booking'">
            <div class="customerInfos mb-3" v-if="customerActive">
              <p class="mb-1" v-if="customerActive.infos && customerActive.infos.taxRule">Für den Kunden ist folgende Steuer-Regel hinterlegt:
                {{ customerActive.infos.taxRule.rule_name }} | Umsatzsteuersatz:
                {{ customerActive.infos.taxRule.rule_factor }}%</p>
              <p class="mb-1" v-if="customerActive.c_discount">Rabatt: {{ customerActive.c_discount }}%</p>
              <p class="mb-1"
                 v-if="customerActive.project_free_hours_monthly && customerActive.project_free_hours_monthly !== '0.00'">
                Freikontingent im Monat: {{ customerActive.project_free_hours_monthly }}</p>
            </div>
            <div v-else>
              <p class="mb-1" v-if="documentCopy.customer.infos && documentCopy.customer.infos.taxRule">Für den Kunden ist folgende Steuer-Regel hinterlegt:
                {{ documentCopy.customer.infos.taxRule.rule_name }} | Umsatzsteuersatz:
                {{ documentCopy.customer.infos.taxRule.rule_factor }}%</p>
              <p class="mb-1" v-if="documentCopy.customer.c_discount">Rabatt: {{ documentCopy.customer.c_discount }}%</p>
              <p class="mb-1"
                 v-if="documentCopy.customer.project_free_hours_monthly && documentCopy.customer.project_free_hours_monthly !== '0.00'">
                Freikontingent im Monat: {{ documentCopy.customer.project_free_hours_monthly }}</p>
            </div>
            <div class="productList fixedList" :class="(showList) ? 'active': ''">
              <div class="searchHead">
                <div class="row">
                  <div class="col-2"><span class="text-primary" @click="toggleList"><i
                    class="fa-duotone fa-arrow-right-from-bracket"></i></span></div>
                  <div class="col-10">
                    <div class="input-group input-group-sm">
                      <input type="text" class="form-control" v-model="filterProduct" @keyup="handleKeyUp"
                             ref="searchInput"
                             placeholder="Produktschnellsuche">
                      <div class="input-group-append" v-if="filterProduct">
                        <button class="btn btn-outline-secondary resetButton" type="button" @click="clearFilter"><i
                          class="fa-duotone fa-x"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <perfect-scrollbar style="max-height: 50vh;">
                <template v-for="(product,index) in filteredList">
                  <div class="productItem" :class="{ 'highlighted': highlightedIndex === index }">
                    <ul class="productInfoBar inline" @click.stop="addProduct(product)">
                      <li class="arrow"><i class="fa-duotone fa-plus"></i></li>
                      <li class="title"><span>{{ product.p_title }}</span></li>
                      <li class="quantity">{{ product.p_default_quantity }}</li>
                      <li class="unit">{{ product.p_default_unit }}</li>
                      <li class="price">{{ $filters.priceEuro(product.p_default_price) }}</li>
                    </ul>
                  </div>
                </template>
              </perfect-scrollbar>
            </div>
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="inDocument">
                  <div class="row mb-3 headbox">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      <label class="text-xs">Rabattierung</label>
                      <div class="input-group input-group-sm">
                        <input type="number" placeholder="Rabattierung" class="form-control" step="1" min="0" max="100"
                               v-model="documentCopy.customer_discount" @change="recalcProducts()"></div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      <template v-if="getConfigs.costnumbers">
                        <label class="text-xs">Kostenstelle</label>
                        <div class="input-group input-group-sm">
                          <select v-model="documentCopy.cost">
                            <option v-for="cost in getConfigs.costNumbers" v-bind:key="cost.id">{{
                                cost.cost_name
                              }}
                            </option>
                          </select>
                        </div>
                      </template>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 align-right">
                      <div class="text-end">
                        <label class="text-xs">Preisberechnung</label>
                        <span v-if="documentCopy.use_nettobrutto === 'netto'"
                              class="fullPriceRender  align-left" tooltip="Summe"
                        ><i
                          class="fa-duotone fa-cash-register"></i> {{
                            $filters.priceEuro(documentCopy.complete_netto)
                          }} </span>
                        <span v-if="documentCopy.use_nettobrutto === 'brutto'"
                              class="fullPriceRender" tooltip="Summe"
                        ><i class="fa-duotone fa-cash-register"></i> {{
                            $filters.priceEuro(documentCopy.complete_brutto)
                          }}</span>
                        <template
                          v-if="documentCopy.discountable && documentCopy.discountable.reduces && documentCopy.customer_discount > 0">
                          <br>
                          <template v-if="documentCopy.use_nettobrutto === 'netto'">
                            <small tooltip="Ursprünglicher Preis"><i class="fa-duotone fa-money-bill-trend-up"></i>
                              {{ $filters.priceEuro(documentCopy.discountable.originalSum.netto) }}</small><br>
                            <small tooltip="Rabatt"><span><i
                              class="fa-duotone fa-money-bills"></i></span>
                              {{ $filters.priceEuro(documentCopy.discountable.reduces.netto) }}</small>
                          </template>
                          <template v-if="documentCopy.use_nettobrutto === 'brutto'">
                            <small tooltip="Ursprünglicher Preis"><i class="fa-duotone fa-money-bill-trend-up"></i>
                              {{ $filters.priceEuro(documentCopy.discountable.originalSum.brutto) }}</small><br>
                            <small tooltip="Rabatt"><span><i
                              class="fa-duotone fa-money-bills"></i></span>
                              {{ $filters.priceEuro(documentCopy.discountable.reduces.brutto) }}</small>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="productList">
                    <div class="productListHeadline" v-if="documentCopy.items && documentCopy.items.length">
                      <ul class="productInfoBar">
                        <li class="drag"></li>
                        <li class="title"></li>
                        <li class="quantity">Menge</li>
                        <li class="unit">Einheit</li>
                        <li class="price">Einzelpreis</li>
                        <li class="tax">Steuerregel</li>
                        <li class="discount"></li>
                        <li class="final_price line"> Gesamtbetrag €
                        </li>
                        <li class="action">
                        </li>
                      </ul>
                    </div>
                    <draggable v-model="documentCopy.items" class="dragProducts" group="products" :itemKey="'id'"
                               handle=".drag"
                               @update:modelValue="updateProducts">
                      <template #item="{ element, index }">

                        <div class="productWrap" :class="(element.type) ? element.type+' '+element.css :'' "
                             v-if="documentCopy.items && documentCopy.items.length" :key="index">

                          <div class="productItem">
                            <ul class="productInfoBar inline" :class="(showEditMode === index) ? 'active': ''">
                              <li class="drag"><i class="fa-duotone fa-up-down"></i></li>
                              <li class="type">
                                <template v-if="element.type ==='product' || !element.type"><i
                                  class="fa-duotone fa-tags"></i></template>
                                <template v-else><i class="fa-duotone fa-folder"></i></template>
                              </li>
                              <li class="title"><input type="text" v-model="element.di_title"/></li>
                              <li class="quantity" v-if="element.type ==='product' || !element.type"><input
                                type="number" v-model="element.di_quantity"
                                @change="calcPrice(element, index)"/></li>
                              <li class="unit" v-if="element.type ==='product' || !element.type"><input type="text"
                                                                                                        v-model="element.di_unit"/>
                              </li>
                              <li class="price" v-if="element.type ==='product' || !element.type"><input type="text"
                                                                                                         v-model="element.di_base_price"
                                                                                                         @change="calcPrice(element, index)"/>
                              </li>
                              <li class="tax" v-if="element.type ==='product' || !element.type"><select
                                v-model="element.tax_group_id" class="form-select small">
                                <option v-for="tax in getConfigs.taxGroups" :value="tax.id">{{ tax.name }}</option>
                              </select></li>
                              <li class="discount" v-if="element.type ==='product' || !element.type"><label
                                 class="blocklabel"
                                tooltip="Rabatt anwenden"
                                position="left">

                                <input type="checkbox" :checked="(element.discountable === 'discountable')"
                                      @click="element.discountable = (element.discountable === 'discountable') ? 'not_discountable' : 'discountable'; calcPrice(element, index)"
                                       class="form-check-input" /> <i
                                class="fa-duotone fa-badge-percent"></i>
                              </label>
                              </li>

                              <li class="final_price line">
                                <template v-if="element.css ==='folder'">
                                  <span class="folderSum"
                                        tooltip="Zwischensumme">{{ formatValue(element.folderSum) }}</span>
                                  <!--                                  <input type="text" v-model="element.folderSum"  readonly class="folderSum" />-->
                                </template>
                                <template v-else>
                                  <input type="text" v-model="element._useVuePrice" v-if="element._useVuePrice" readonly/>
                                  <input type="text" v-model="element.di_sum_price" v-else readonly/>
                                </template>
                              </li>
                              <li class="action">
                                <template v-if="showEditMode === index">
                                  <span @click="toggleEditMode(index)"><i class="fa-duotone fa-chevrons-up"></i></span>
                                </template>
                                <template v-else>
                                  <span @click="toggleEditMode(index)"><i
                                    class="fa-duotone fa-chevrons-down"></i></span></template>
                                <span @click="removeProduct(index)"><i class="fa-duotone fa-trash-can text-danger"></i></span>
                              </li>
                            </ul>
                            <div class="editorWrap" v-if="showEditMode === index">
                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>Titel</label>
                                    <input type="text" class="form-control" v-model="element.di_title"/>
                                  </div>
                                  <div class="form-group">
                                    <label>Beschreibung</label>
                                    <Editor2 v-model="element.di_description"
                                             :customConfig="{toolbar: {items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],removeItems: ['imageUpload', 'blockQuote', 'mediaEmbed']}}"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'docs'">
            <p>Die Platzhalter werden beim Speichern gefüllt.<br><br>
              Zur Verfügung stehen: ##SALUTATION## , ##TITLE## , ##INFO## , ##OWNER## , ##DOCNUMBER##, ##DATE##</p>
            <div class="form-group" v-if="documentCopy.customFields && documentCopy.customFields.document_sending">
              <label>Betreff</label>
              <input type="text" class="form-control" v-model="documentCopy.customFields.document_sending.subject"/>
            </div>
            <div class="form-group" v-if="documentCopy.customFields && documentCopy.customFields.document_sending">
              <label>Text</label>
              <Editor2 v-model="documentCopy.customFields.document_sending.body"/>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'contract'">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Startzeitpunkt</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.contract_start_date" model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Startzeitpunkt" :auto-apply="true" :close-on-auto-apply="true" locale="de"
                                 :enable-time-picker="false" cancel-text="X" :select-text="this.$t('glboals.choose')"
                                 :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Vertragsdatum</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.contract_sign_date" model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Vertragsdatum" :auto-apply="true" :close-on-auto-apply="true" locale="de"
                                 :enable-time-picker="false" cancel-text="X" :select-text="this.$t('glboals.choose')"
                                 :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Datum der letzten Abrechnung</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.first_invoice_date" model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Datum der letzten Abrechnung" :auto-apply="true"
                                 :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('glboals.choose')" :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Datum der Kündigung</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.contract_termination_date"
                                 model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Datum der Kündigung" :auto-apply="true" :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('glboals.choose')" :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Vertragsstatus</label>
                  <div class="btn-group btn-group-yesno" role="group">
                    <input type="radio" class="btn-check" id="contract_state_0" value="accepted"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-success" for="contract_state_0">Aktiv / Bestätigt</label>
                    <input type="radio" class="btn-check" id="contract_state_1" value="waiting"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-secondary" for="contract_state_1">In Bearbeitung</label>
                    <input type="radio" class="btn-check" id="contract_state_2" value="terminated"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-danger" for="contract_state_2">Gekündigt</label>
                    <input type="radio" class="btn-check" id="contract_state_3" value="died"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-secondary" for="contract_state_3">Verstorben</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Auto-Verlängerung</label>
                  <div class="btn-group btn-group-yesno" role="group">
                    <input type="radio" class="btn-check" id="contract_repeating_0" value="yes"
                           v-model="documentCopy.contractDetails.contract_repeating">
                    <label class="btn btn-outline-success" for="contract_repeating_0">Ja</label>
                    <input type="radio" class="btn-check" id="contract_repeating_1" value="yearly"
                           v-model="documentCopy.contractDetails.contract_repeating">
                    <label class="btn btn-outline-secondary" for="contract_repeating_1">pro Geschäftsjahr</label>
                    <input type="radio" class="btn-check" id="contract_repeating_2" value="no"
                           v-model="documentCopy.contractDetails.contract_repeating">
                    <label class="btn btn-outline-danger" for="contract_repeating_2">Nein</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Laufzeit in Monaten</label>
                  <input type="number" v-model="documentCopy.contractDetails.contract_runtime_months"
                         class="form-control"/>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label class="mb-2">Laufzeit als Menge Nutzen?</label>
                  <div class="btn-group btn-group-yesno" role="group">
                    <input type="radio" class="btn-check" id="change_quantity_0" value="yes"
                           v-model="documentCopy.contractDetails.change_quantity">
                    <label class="btn btn-outline-success" for="change_quantity_0">Ja</label>
                    <input type="radio" class="btn-check" id="change_quantity_2" value="no"
                           v-model="documentCopy.contractDetails.change_quantity">
                    <label class="btn btn-outline-danger" for="change_quantity_2">Nein</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Vertragsende <small>(ergibt sich aus Laufzeit oder
                    Kündigung)</small></label>
                  <div class="inputPlaceholder">{{
                      $filters.formatDate(documentCopy.contractDetails.contract_end)
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group"><label>Aktuelle Kündigungsfrist</label>
                  <div class="inputPlaceholder">{{
                      $filters.formatDate(documentCopy.contractDetails.next_termination_date)
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="mb-2">Rechnungserstellung</label>
                  <select class="form-select" v-model="documentCopy.contractDetails.contract_invoice_period">
                    <option value="monthly">Monatlich
                    </option>
                    <option value="quartaly">Quartalsweise
                    </option>
                    <option value="halfyearly">Halbjährlich
                    </option>
                    <option value="firstmonthofyear">Anfang des Geschäftsjahres
                    </option>
                    <option value="yearly">Jährlich
                    </option>
                    <option value="no">Keine
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Kündigungsfrist in Wochen</label>
                  <input type="number" v-model="documentCopy.contractDetails.contract_termination_weeks"
                         class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group"><label>Custom Regeln</label><textarea
                  v-model="documentCopy.contractDetails.custom_rules"
                  cols="30"
                  rows="5"></textarea></div>
              </div>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'preview'">
            <embed :src="'data:application/pdf;base64,'+pdfPreview" type="" style="min-height: 80vh; width: 100%;" v-if="pdfPreview">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import products from "../Products/Products.vue";
import Editor2 from "@/components/Editor2";
import draggable from 'vuedraggable'
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";
import customersearch from "@/filter/components/customersearch";
import Multiselect from "@vueform/multiselect";
export default {
  name: "Edit",
  props: {
    document: {
      type: Object,
    },
    modal: false,
  },
  computed: {
    ...mapGetters(["modalsList", "getEmployees", "customerActive", "productList", "getConfigs", "getUser"]),
  },
  components: {
    VueDatePicker,
    products,
    Editor2,
    draggable,
    customersearch,
    Multiselect,
  },
  data() {
    return {
      filterProduct: '',
      localProducts: [],
      allProducts:[],
      customer: false,
      showList: true,
      showFolderDropdown: false,
      showEditMode: '',
      documentCopy: {},
      sortable: false,
      draggingProducts: {},
      highlightedIndex: -1,
      preparedDocument: {
        items: [],
        complete_sum: 0,
        complete_netto: 0,
        complete_brutto: 0,
        d_catid: '',
        contractDetails: {
          contract_start_date: '',
          contract_sign_date: '',
          first_invoice_date: '',
          contract_termination_date: '',
          contract_state: 'accepted',
          contract_repeating: 'yes',
          contract_runtime_months: '12',
          change_quantity: 'no',
          end: '',
          contract_termination_weeks: '',
          contract_invoice_period: 'monthly',
          custom_rules: '',
        },
        customFields: {
          document_sending: {
            subject: '',
            body: '',
          }
        },
        doc_number: '',
        doc_d_date: new Date().toISOString().slice(0, 10),
        doc_dl_date: new Date().toISOString().slice(0, 10),
        doc_pay_date: '',
        show_nettobrutto: 'netto',   // Default to "Netto"
        use_nettobrutto: 'netto',    // Default to "Netto"
        invoice_type: '1',           // Default to "Standard"
        active_mode: 'active',       // Default to "Aktiv"
        items_sorting: 'default',    // Default to "Standard"
        special_owner: '0',
        d_state: '0',
        address_id: '0',
      },
      isDragging: false,
      orderTabs: [
        {name: 'base', label: 'Basiseinstellungen', icon: 'fa-cog'},
        {name: 'booking', label: 'Abrechnungsdaten', icon: 'fa-coin'},
        {name: 'docs', label: 'Dokumenten-Versand', icon: 'fa-envelope'},
        {name: 'contract', label: 'Vertragsdetails', icon: 'fa-file'},
        {name: 'preview', label: 'Vorschau', icon: 'fa-file'},
      ],
      activeOrderTab: 'base',
      filteredList: [],
      addresses: {},
      isContract: false,
      pdfPreview: '',
    }
  },
  methods: {
    getProducts(){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "product/search?offset=0&limit=0" , {headers}).then((r) => {
        this.allProducts = r.data.result.data;
      });
    },
    toggleList() {
      this.showList = !this.showList;
      this.showFolderDropdown = false;
      if (this.showList) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    toggleEditMode(key) {
      if (this.showEditMode === key) {
        this.showEditMode = '';
      } else {
        this.showEditMode = key;
      }
    },
    previewPdf() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "documents/" + this.document.id + "/" + 'previewMail', {headers}).then((r) => {
        //   PDF Base64
        this.pdfPreview = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    getAddresses(customerId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/addresses", {headers}).then((r) => {
        this.addresses = r.data.result.data;
      });
    },
    updateProducts(event) {
      this.localProducts = event;
      this.documentCopy.items = [...event];
      this.calcFullPrice();
    },
    filterProducts: function () {
      let searchword = this.filterProduct.toLowerCase();
      this.filteredList = this.allProducts.filter(function (el) {
        return el.p_title.toLowerCase().includes(searchword) ||
          el.p_desc.toLowerCase().includes(searchword);
      });
    },

    setNewDocument() {

      let today = new Date();
      let paramDays = this.getConfigs.params_values.payment_due_date;
      let dueDate = new Date(today.setDate(today.getDate() + parseInt(paramDays)));
      this.documentCopy.doc_pay_date = dueDate.toISOString().slice(0, 10);
      // set User
      this.documentCopy.d_created_author = this.getUser.id;
      if (this.customerActive && this.customerActive.c_discount) {
        this.documentCopy.customer_discount = this.customerActive.c_discount;
      } else {
        this.documentCopy.customer_discount = 0;
      }
      this.documentCopy.customFields.document_sending.subject = this.getConfigs.params_values.document_sent_subject;
      this.documentCopy.customFields.document_sending.body = this.getConfigs.params_values.document_sent;
      const defaultDocCat = this.getConfigs.categories_documents.find(doc => doc.use_as_default === '1');
      if (defaultDocCat) {
        this.documentCopy.d_catid = defaultDocCat.id;
      } else {
        // Fallback to the first customerState if no default is marked
        this.documentCopy.d_catid = this.getConfigs.categories_documents[0].id;
      }
    },
    handleKeyUp(event) {
      switch (event.key) {
        case "ArrowDown":
          // Move down the list
          if (this.highlightedIndex < this.filteredList.length - 1) {
            this.highlightedIndex++;
          }
          break;
        case "ArrowUp":
          // Move up the list
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          break;
        case "Enter":
          // Select the highlighted product
          if (this.highlightedIndex >= 0 && this.highlightedIndex < this.filteredList.length) {
            this.addProduct(this.filteredList[this.highlightedIndex]);
          }
          break;
        case "Escape":
          // Close the list
          this.showList = false;
          this.filterProduct = '';
          break;
        default:
          this.filterProducts();
          break;
      }
    },
    addProduct: function (product) {
      const clonedProduct = JSON.parse(JSON.stringify(product));
      const useProduct = {};
      useProduct.di_title = clonedProduct.p_title;
      useProduct.di_description = clonedProduct.p_desc;
      useProduct.di_quantity = clonedProduct.p_default_quantity;
      useProduct.di_unit = clonedProduct.p_default_unit;
      useProduct.tax_group_id = clonedProduct.tax_group_id;
      useProduct.di_base_price = clonedProduct.p_base_price;
      useProduct.di_cost_number_id = clonedProduct.p_cost_number_id;
      useProduct.product_id = clonedProduct.id;
      useProduct.discountable = clonedProduct.discountable;
      useProduct.di_sum_price = clonedProduct.p_base_price * clonedProduct.p_default_quantity;
      useProduct.di_sum_price = parseFloat(useProduct.di_sum_price).toFixed(2);
      useProduct._useVuePrice = useProduct.di_sum_price;
      useProduct.type = 'product'
      useProduct.css = '';
      useProduct.di_ordering = '';

      // Check if documentCopy.items exists and has items
      if (this.documentCopy.items && this.documentCopy.items.length > 0) {
        this.documentCopy.items.push(useProduct);
      } else {
        this.localProducts.push(useProduct);
        this.documentCopy.items = [...this.localProducts];
      }
      this.calcFullPrice();
      this.$refs.searchInput.focus();
    },
    addFolder(type) {
      const useFolder = {};
      useFolder.di_title = '';
      if (type === 'spacer') {
        useFolder.di_title = 'Trenner';
      }
      if (type === 'required') {
        useFolder.di_title = 'Pflicht-Auswahl'
      }
      if (type === 'optional') {
        useFolder.di_title = "Zusatzoptionen"
      }
      useFolder.di_description = '';
      useFolder.di_base_price = 0;
      useFolder.di_quantity = 0;
      useFolder.di_unit = '';
      useFolder.tax_group_id = '';
      useFolder.di_cost_number_id = '';
      useFolder.product_id = '';
      useFolder.discountable = '';
      useFolder.di_sum_price = '0';
      useFolder.css = 'folder';
      useFolder.type = type;
      useFolder.di_ordering = '';

      useFolder.folderSum = 0;
      // Check if documentCopy.items exists and has items
      if (this.documentCopy.items && this.documentCopy.items.length > 0) {
        this.documentCopy.items.push(useFolder);
      } else {
        this.localProducts.push(useFolder);
        this.documentCopy.items = [...this.localProducts];
      }
      this.showFolderDropdown = false;
    },
    recalcProducts(){
      this.documentCopy.items.forEach((item, index) => {
        if (item.type === 'product') {
          this.calcPrice(item, index);
        }
      });
    },
    removeProduct(productIndex) {
      this.documentCopy.items.splice(productIndex, 1);
      this.calcFullPrice();
    },
    calcPrice(product, prodkey) {
      // set prices/quantatiy to 0 if is Nan
      if (isNaN(product.di_quantity) || product.di_quantity === '') {
        product.di_quantity = 0;
      }
      if (isNaN(product.di_base_price) || product.di_base_price === '') {
        product.di_base_price = 0;
      }
      if (isNaN(product.di_sum_price || product.di_sum_price === '')) {
        product.di_sum_price = 0;
      }
      product.di_sum_price = parseFloat(product.di_base_price) * parseFloat(product.di_quantity);
      product.di_sum_price = parseFloat(product.di_sum_price).toFixed(2);
      if (product.discountable === 'discountable' && this.documentCopy.customer_discount > 0) {

        product.di_sum_price = parseFloat(product.di_sum_price) - (parseFloat(product.di_sum_price) * (parseFloat(this.documentCopy.customer_discount) / 100));
        product.di_sum_price = parseFloat(product.di_sum_price).toFixed(2);
      }
      product._useVuePrice = product.di_sum_price;
      product.di_sum_price = parseFloat(product.di_base_price) * parseFloat(product.di_quantity);
      this.documentCopy.items[prodkey] = product;
      this.calcFullPrice();
    },
    updateCustomer() {
      this.$store.dispatch('setActiveCustomer', this.customer.id);
      this.getAddresses(this.customer.id);
      if (this.customer.id) {
        this.documentCopy.c_id = this.customer.id;
      }
    },
    savePreview(){
      if (this.activeOrderTab === 'preview') {
        this.updateDocument(false);
      }
    },
    updateDocument(close) {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.documentCopy.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "document/" + VueInner.documentCopy.id, VueInner.documentCopy, {headers}).then(() => {


          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Dokument wurde erfolgreich gespeichert');
          // Load Products
          if(close){
            VueInner.$store.dispatch('documentsFilter');
            VueInner.$store.commit('closeDocument');
            VueInner.$router.push({name: 'Documents'});
          }

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "document", VueInner.documentCopy, {headers}).then(() => {
          VueInner.$store.commit('closeModal', 'document');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Dokument wurde erfolgreich angelegt');
          // Load Products
          VueInner.$store.dispatch('documentsFilter');
          this.$router.push({name: 'Documents'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    calcFullPrice() {
      let fullPrice = 0;
      let currentFolder = null;
      for (let i = 0; i < this.documentCopy.items.length; i++) {
        const item = this.documentCopy.items[i];
        const isFolder = item.css === 'folder';  // Using the css attribute for determining folder
        // If it's a new folder or the last item
        if (isFolder || i === this.documentCopy.items.length - 1) {
          if (currentFolder) {
            // Finalize the current folder's sum before moving on to the next folder
            currentFolder.folderSum = isNaN(currentFolder.folderSum) ? 0 : currentFolder.folderSum;
          }
          if (isFolder) {
            currentFolder = item;
            currentFolder.folderSum = 0;  // Reset the sum for the new folder
          }
        }
        // If it's a product, update the full price and folder sum (if there's a current folder)
        if (item.type === 'product') {
          const productSum = parseFloat(item.di_sum_price);
          if (!isNaN(productSum)) {
            fullPrice += productSum;
            if (currentFolder) {
              currentFolder.folderSum += productSum;
            }
          }
        }
      }
      this.documentCopy.complete_netto = fullPrice.toFixed(2);
      this.documentCopy.complete_brutto = (fullPrice * 1.19).toFixed(2);
      // Adjust discountable.reduces
      if (this.documentCopy.discountable && this.documentCopy.discountable.reduces) {
        this.documentCopy.discountable.reduces.netto = this.documentCopy.discountable.originalSum.netto - this.documentCopy.complete_netto;
        this.documentCopy.discountable.reduces.brutto = this.documentCopy.discountable.originalSum.brutto - this.documentCopy.complete_brutto;
      }
    },
    toggleDropdown() {
      this.showFolderDropdown = !this.showFolderDropdown
    },
    clearFilter() {
      this.filterProduct = '';
    },
    formatValue(value) {
      if (typeof value !== 'number') {
        return '0.00';
      }
      return value.toFixed(2);
    }
  },
  mounted() {
    this.getProducts();
    this.filteredList = this.productList.data;
    // if this.document is empty object use default values from data
    if (Object.keys(this.document).length > 0) {
      this.documentCopy = JSON.parse(JSON.stringify(this.document));
      if (this.documentCopy.use_nettobrutto === "netto") {
        this.documentCopy.items = this.documentCopy.items.map((item) => {
          item.di_sum_price = parseFloat(item._calcedPrices.netto).toFixed(2);
          return item;
        });
      } else {
        this.documentCopy.items = this.documentCopy.items.map((item) => {
          item.di_sum_price = parseFloat(item._calcedPrices.brutto).toFixed(2);
          return item;
        });
      }
      if (this.customerActive && this.customerActive.id) {
        this.documentCopy.c_id = this.customerActive.id;
      }
      if (this.customer.id) {
        this.documentCopy.c_id = this.customer.id;
      }
    } else {
      this.documentCopy = this.preparedDocument;
      if (this.customerActive && this.customerActive.id) {
        this.documentCopy.c_id = this.customerActive.id;
      }
      if (this.customer.id) {
        this.documentCopy.c_id = this.customer.id;
      }
      this.setNewDocument();
    }
    if (this.documentCopy.c_id) {
      this.getAddresses(this.documentCopy.c_id);
    }
    if (this.$route.params.id !== 'new') {
      this.showList = false;
    } else {
      this.showList = true;

    }
    if(this.$route.params.tab === 'documents'){
      this.activeOrderTab = 'base';
      this.showList = true;


    } else {
    if (this.$route.params.tab) {

      this.activeOrderTab = this.$route.params.tab;
    } else {
      this.activeOrderTab = 'base';
    }}

  },
  watch: {
    $route(to) {
      this.getProducts();
      if (to.params.tab === 'documents') {

        this.activeOrderTab = 'booking';
      } else {
        if (to.params.tab) {
          this.activeOrderTab = to.params.tab;
        } else {
          this.activeOrderTab = 'base';
        }
      }
    },
    activeOrderTab: {
       // if(this.activeOrderTab === 'preview'){
       //   this.previewPdf();
       // }
      handler: function(val,oldVal){
        if(val === 'preview'){
          this.previewPdf();
        }
      }
    },
    customer: {
      handler: function (val, oldVal) {
        this.updateCustomer();
      },
      deep: true
    },
    documentCopy: {
      handler: function (val, oldVal) {
        this.isContract = false;
        if (val.d_catid) {
          let b;
          let a;
          for (b in this.getConfigs.categories_documents) {
            a = this.getConfigs.categories_documents[b];
            if (a.id === val.d_catid && a.isContract) {
              this.isContract = true;
            }
          }
        }
        // Check what's happening inside this watcher
      },
      deep: true
    },
    document: {
      handler: function (val, oldVal) {
        if (Object.keys(this.document).length > 0) {
          this.documentCopy = JSON.parse(JSON.stringify(this.document));
          if (this.documentCopy.use_nettobrutto === "netto") {
            this.documentCopy.items = this.documentCopy.items.map((item) => {
              item.di_sum_price = parseFloat(item._calcedPrices.netto).toFixed(2);
              return item;
            });
          } else {
            this.documentCopy.items = this.documentCopy.items.map((item) => {
              item.di_sum_price = parseFloat(item._calcedPrices.brutto).toFixed(2);
              return item;
            });
            if (this.customerActive && this.customerActive.id) {
              this.documentCopy.c_id = this.customerActive.id;
            }
            if (this.customer.id) {
              this.documentCopy.c_id = this.customer.id;
            }
          }
        } else {
          this.documentCopy = this.preparedDocument;
          if (this.customerActive && this.customerActive.id) {
            this.documentCopy.c_id = this.customerActive.id;
          }
          if (this.customer.id) {
            this.documentCopy.c_id = this.customer.id;
          }
          this.setNewDocument();
        }
      },
      deep: false
    },

  }
}
</script>
<style>
.productItem.blur {
//filter: blur(3px);
}

.dragging {
  opacity: 0.5;
}

.inputPlaceholder {
  display: block;
  border-bottom: 1px solid #333;
  background: #eee;
  padding: 12px 6px;
  cursor: not-allowed;
  min-height: 48px;
  border-radius: 7px 7px 0 0;
}
</style>
