<template>
  <div class="card" v-if="file">
    <div class="card-header">{{ file.title }} <img
      :src="$filters.employeeIcon(getEmployees, file.uploaded_by, 'u_id')"
      class="sketchAvatar"> {{ $filters.formatDate(file.uploaded_on) }}<i class="fa-duotone fa-x fa-swap-opacity float-end"
                                                                          @click="closeFile"></i>
    <div class="btn btn-outline-primary btn-sm float-end me-3" @click="postFile"><i class="fa-duotone fa-floppy-disk"></i> Speichern</div>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-6">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="radiogroup" v-model="doctype" id="option1"
                   value="invoice" checked>
            <label class="form-check-label" for="option1">
              Rechnung
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="doctype" name="radiogroup" id="option2"
                   value="custom">
            <label class="form-check-label" for="option2">
              anderes Dokument
            </label>
          </div>
          <div class="invoiceWrap" v-if="doctype === 'invoice'">
            <customersearch v-model="invoice.customer"/>
            <div class="form-group">
              <input type="text" class="form-control" v-model="invoice.title" placeholder="Titel">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="invoice.invoice_number" placeholder="Rechnungsnummer">
            </div>
            <div class="form-group">
              <input type="number" class="form-control" v-model="invoice.price" placeholder="Preis" step="0.01">
            </div>
            <div class="form-group">

              <VueDatePicker v-model="invoice.invoice_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="Rechnungsdatum"
                             :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                             cancel-text="X" select-text="Wählen" :teleport="true">
              </VueDatePicker>
            </div>
            <div class="form-group">
              <select class="form-select" v-model="invoice.type" id="invoiceType">
                <option value="in">Eingehende Rechnung</option>
                <option value="ou">Manuell erstellte ausgehende Rechnung</option>
              </select>
            </div>
            <div class="form-group">
              <select class="form-select" v-model="invoice.sepa" id="invoiceType">
                <option value="0">Manuelle Überweisung</option>
                <option value="1">SEPA-Einzug vorhanden</option>
                <option value="2">Dauerauftrag vorhanden</option>
              </select>
            </div>
            <div class="form-group">
              <VueMultiselect
                :options="getConfigs.accountingKeys"
                :multiple="false"
                :internal-search="true"
                :close-on-select="true"
                :show-labels="false"
                valueProp="id"
                class="mb-3"
                :label="'title'"
                placeholder="Sachkonto wählen"
                v-model="invoice.accounting_key"
              />
            </div>

            <div class="form-group">
              <select class="form-select" v-model="invoice.dauerbuchung" id="dauerbuchung">
                <option value="0">Keine Dauerrechnung</option>
                <option value="1">Dauerrechnung erstellen</option>
              </select>
            </div>
            <!-- accounting_year -->
            <div class="form-group">
              <label for="textInput">Buchungsjahr</label>
              <input class="form-control" type="number" step="1" min="2022" v-model="invoice.accounting_year">
            </div>
            <div class="form-group">
              <label for="accountingPeriod">Buchungszeitraum:</label>
              <select class="form-select" v-model="invoice.accounting_period" required id="accountingPeriod">
                <option value="year_complete">Komplettes Geschäftsjahr</option>
                <option value="year_rest">Restliches Geschäftsjahr</option>
                <option value="month">Aktueller Monat</option>
                <option value="quartal_1st">1. Quartal</option>
                <option value="quartal_2nd">2. Quartal</option>
                <option value="quartal_3rd">3. Quartal</option>
                <option value="quartal_4th">4. Quartal</option>
                <option value="half_1st">1. Halbjahr</option>
                <option value="half_2nd">2. Halbjahr</option>
              </select>
            </div>
          </div>
          <div class="otherWrap" v-if="doctype === 'custom'">
            <customersearch v-model="custom.customer"/>
            <div class="form-group">
              <label for="textInput">Titel</label>
              <input type="text" class="form-control" v-model="custom.title">
            </div>
            <div class="form-group">
              <label for="textInput">Beleg-/Eingangsdatum</label>
              <div class="form-group">
                <VueDatePicker v-model="custom.doc_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :auto-apply="true"
                               :close-on-auto-apply="true" locale="de" :enable-time-picker="false" cancel-text="X"
                               select-text="Wählen" :teleport="true">
                </VueDatePicker>
              </div>
            </div>
            <div class="form-group">
              <label for="textInput">Ordner wählen</label>
              <select class="form-select" name="" id="" v-model="custom.folder">
                <option :value="folder.hash"  v-for="folder in folders">{{folder.title}}</option>
              </select>


            </div>
            <div class="form-group">
              <label for="textInput">Neuen Ordner anlegen</label>
              <input type="text" class="form-control" v-model="custom.new_folder">
            </div>
            <div class="form-group">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="visibleToCustomers" v-model="custom.visibility">
                <label class="form-check-label" for="visibleToCustomers">Für Kunden sichtbar?</label>
              </div>
            </div>
            <div class="form-group" v-if="!custom.customer">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="visibleToDrafts" v-model="custom.draft">
                <label class="form-check-label" for="visibleToDrafts">Als Vorlage verwenden?</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <iframe :src="'data:'+file.mime_type+';base64,'+fileEncoded" width="100%" height="650px"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueMultiselect from "vue-multiselect";
import customersearch from "@/filter/components/customersearch.vue";
export default {
  name: "Details",
  components: {VueDatePicker, VueMultiselect, customersearch },
  emits: ["close-file", "refreshTempFiles"],
  props: {
    file: Object
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs"])
  },
  data() {
    return {
      fileEncoded: false,
      fileInfos: false,
      usedExtension: false,
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
      doctype: 'invoice',
      invoice: {
        title: '',
        invoice_number: '',
        price: '',
        invoice_date: '',
        accounting_year: new Date().getFullYear(),
        type: "in",
        sepa: '0',
        accounting_period: 'month',
        dauerbuchung: '0',
        accounting_key: '',
        customer: false,
      },
      custom: {
        title: '',
        doc_date: '',
        visibility: false,
        customer: false,
        new_folder: '',
      },
      doc: {
        title: false,
      },
      folders: [],
    }
  },
  methods: {
    closeFile() {
      this.$emit("close-file");
    },
    getFileDetails() {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'files/uploadTemp/' + this.file.id, {headers}).then(response => {
        this.fileEncoded = response.data.data;
        this.folders = response.data.folders;
        this.fileInfos = response.data;
      });
    },
    postFile() {
      // prepare invoice
      let postinvoice
      if(this.doctype === 'invoice') {
        postinvoice = this.invoice;
        if (!this.invoice.customer) {
          // toast
          this.$store.dispatch('toastError', 'Bitte einen Kunden auswählen');
          return false;
        }
        // check if fill all fields
        if (!this.invoice.title || !this.invoice.invoice_number || !this.invoice.price || !this.invoice.invoice_date || !this.invoice.accounting_key) {
          // toast
          this.$store.dispatch('toastError', 'Bitte alle Felder ausfüllen');
          return false;
        }


        postinvoice.customer_id = this.invoice.customer.id;
        postinvoice.accounting_key = this.invoice.accounting_key.id;
      } else {

        if (!this.custom.folder && !this.custom.new_folder) {
          // toast
          this.$store.dispatch('toastError', 'Bitte einen Ordner auswählen oder einen neuen anlegen');
          return false;
        }
        postinvoice = this.custom;
        if (this.custom.customer) {
        postinvoice.customer_id = this.custom.customer.id;
        }
        postinvoice.draft = this.custom.draft ? 1 : 0;
      }

      let headers = this.headers;

      axios.post(this.$store.state.apiUrl + 'files/'+this.file.id+'/move/'+this.doctype  , postinvoice, {headers}).then(response => {
        this.closeFile();
        // reset this.custom
        this.custom = {
          title: '',
          doc_date: '',
          visibility: false,
          customer: false,
          new_folder: '',
        };
        // reset this.invoice
        this.invoice = {
          title: '',
          invoice_number: '',
          price: '',
          invoice_date: '',
          type: "in",
          sepa: '0',
          accounting_period: 'month',
          dauerbuchung: '0',
          accounting_key: '',
          customer: false,
          accounting_year: new Date().getFullYear(),
        };

        // run CustomEvent triggerReloadFiles
        window.dispatchEvent(new CustomEvent('triggerReloadFiles'));

        this.$store.dispatch('toastSuccess', 'Datei erfolgreich zugewiesen');
      }).catch(error => {
        this.$store.dispatch('toastError', 'Datei konnte nicht zugewiesen werden');
      });
    },
    updateAccountingKey(value) {
      this.invoice.accounting_key = value;
    },
  },
  created() {
    if (this.file) {
      this.getFileDetails();
    }
  },
  watch: {
    file: function (val) {
      if (val) {
        this.getFileDetails();
      }
    }
  }
}
</script>
<style scoped>
.fa-x {
  cursor: pointer;
  position: relative;
  top: 8px;
}
</style>
