<template>
  <div class="filesystemFullWrap">
    <div class="card" style="border-radius: 0;">
      <div class="card-header" style="border-radius: 0;"><h6><i class="fa-duotone fa-files"></i> Dateien</h6></div>
      <div class="card-body">
        <div class="folders" v-if="files">
          <template v-for="(FileClass, boxName) in files">
            <template v-if="boxName ==='custom'">
              <template v-for="file in FileClass">
                <div class=" documentbox category" :class="file.title.toLowerCase()"
                     :data-folder="file.title.toLowerCase()" :data-category="file.id">

                  <span @click='navigateHash(file.hash)'>
                     <span class="resizeonhover" :class="file.icon"></span><span
                    class="text">{{ file.title }}</span><span class="items">{{ file._sumItems }} Dokumente</span>
                   </span>
                </div>
              </template>
            </template>
            <template v-else>
              <div class=" documentbox category " :class="boxName.toLowerCase()"
                   :data-folder="boxName" data-category="0"
                   data-categoryname="invoice">
                <div @click="navigateFolder(boxName.toLowerCase())">
                  <span class="resizeonhover" :class="FileClass.toLowerCase()"></span>
                  <span class="text">
                     <template v-if="ucfirst(boxName) === 'Invoices'">Rechnungen</template>
                     <template v-else>{{ ucfirst(boxName) }}</template>
                      </span>
                  <template v-if="ucfirst(boxName) === 'Invoices'">
                    <span class="items"> Dokumente</span>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </div>
        {{ files }}
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: 'Filesystem',
  data() {
    return {
      loading: false,
      percent: 0,
      type: 'full',
      files: [],
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  methods: {
    refreshFiles() {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'documentBox/search', {headers}).then(response => {
        this.files = response.data.result.data;
      }).catch((error) => {
        this.$store.dispatch('handleApiResponse', error.response)
        console.log(error);
      });
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    navigateFolder(folder) {
        console.log(folder);

    },
    navigateHash(hash) {
      console.log(hash);
    }
  },
  created() {
    this.refreshFiles();
    window.addEventListener('triggerReloadFiles', this.refreshFiles);
  },
  beforeUnmount() {
    window.removeEventListener('triggerReloadFiles', this.refreshFiles);
  }
}
</script>
<style scoped>
</style>
