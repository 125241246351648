<template>
  <div class="productItem activeProduct">
    <div class="card">

      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{ $t("ConfigEmployeeCategory.details.heading") }}
        </h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigEmployeeCategory');$store.commit('closeModal', 'ConfigEmployeeCategory');">
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
          class="fa-duotone fa-trash"></i> {{ $t("globals.delete") }}
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label>{{ $t("ConfigEmployeeCategory.details.title") }}</label>
              <input class="form-control" type="text" step="1" v-model="d.title">
            </div>
          </div>
        </div>
          <Acl  :employee="d"  @acl-changed="updateRights"   />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import $ from "jquery";
import Acl from "@/routing/core/Employees/Acl";
export default {
  name: "CategoriesEmployeesDetails",
  components: {
    Editor2,
    Acl
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/categoriesEmployee/" + VueInner.d.id, VueInner.d, {headers}).then(() => {
          // dispatch toastSuccess
          // Load Products
          this.$store.dispatch("loadConfig", {
            type: "categoriesEmployee",
            percent: 35,
            message: 'Fahrstuhlmusik wird geladen'
          }).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Mitarbeiterkategorie wurden erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigEmployeeCategory');
          });
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "config/categoriesEmployee/", VueInner.d, {headers}).then(() => {
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {
            type: "categoriesEmployee",
            percent: 35,
            message: 'Fahrstuhlmusik wird geladen'
          }).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigEmployeeCategory');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Mitarbeiterkategorie wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigEmployeeCategory');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll der Mitarbeiterkategorie wirklich gelöscht werden?', 'Jahresplan löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#3B8130',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/categoriesEmployee/" + VueInner.d.id, {headers}).then(() => {
          // close
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {
            type: "categoriesEmployee",
            percent: 35,
            message: 'Fahrstuhlmusik wird geladen'
          }).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Mitarbeiterkategorie wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigEmployeeCategory');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id && this.getConfigs && this.getConfigs.categoriesEmployee && this.getConfigs.categoriesEmployee.length > 0) {
        this.d = this.getConfigs.categoriesEmployee.find(draft => draft.id === this.$route.params.id);
        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    updateRights(newRights) {
      this.d.rights = newRights;
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>
<style scoped>
h6.inline {
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
