<template>
  <template v-if="$route && $route.matched && $route.matched.length">
<!--    <template v-if="$route.matched[0].name === 'Documents'">-->
<!--      <button class="btn btn-outline-primary btn-sm btn-hide-mobile  me-3" :tooltip="$t('export.documents')"-->
<!--              position="left"-->
<!--              @click="$store.commit('openModal', 'export');"><i class="fa-duotone fa-file-circle-info"></i></button>-->
<!--    </template>-->
    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-primary btn-sm btn-hide-mobile  me-3" :tooltip="$t('report.customers')"
              position="left"
              @click="$store.commit('openModal', 'report');"><i class="fa-duotone fa-file-circle-info"></i></button>
    </template>
<!--    <template v-if="$route.matched[0].name === 'Projects'">-->
<!--      <button class="btn btn-outline-primary btn-sm btn-hide-mobile  me-3" :tooltip="$t('export.projects')"-->
<!--              position="left"-->
<!--              @click="$store.commit('openModal', 'export');"><i class="fa-duotone fa-file-circle-info"></i></button>-->
<!--    </template>-->
  </template>
  <Modal v-if="modalsList.report">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="productItem activeProduct">
          <div class="card">
            <div class="card-header ">
              <button type="button" class="btn btn-close float-end ms-4" style="position: relative;
z-index: 1;"
                      @click="$store.commit('closeModal', 'report');">
              </button>
<!--              <template v-if="$route.matched[0].name === 'Documents'">-->
<!--                <button class="btn btn-outline-primary btn-sm float-end  me-3" :tooltip="$t('export.documents')"-->
<!--                        position="left" style="position: relative;-->
<!--z-index: 1;"-->
<!--                        @click="exportData('documents', exportFields)"><i-->
<!--                  class="fa-duotone fa-file-export"></i> exportieren-->
<!--                </button>-->
<!--              </template>-->
<!--              <template v-if="$route.matched[0].name === 'Projects'">-->
<!--                <button class="btn btn-outline-primary btn-sm float-end  me-3" :tooltip="$t('export.documents')"-->
<!--                        position="left" style="position: relative;-->
<!--z-index: 1;"-->
<!--                        @click="exportData('projects', exportFields)"><i-->
<!--                  class="fa-duotone fa-file-export"></i> exportieren-->
<!--                </button>-->
<!--              </template>-->
              <template v-if="$route.matched[0].name === 'customers'">
                <button class="btn btn-outline-primary btn-sm float-end  me-3" :tooltip="$t('export.customers')"
                        position="left" style="z-index: 1;"
                        @click="exportData('customers', exportFields)"><i
                  class="fa-duotone fa-file-circle-info"></i> Bericht erstellen
                </button>
              </template>
              <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{ $t("export.customers") }}</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6>Diese Felder exportieren:</h6>
                  <p><small>Reihenfolge per Drag & Drop festlegen.</small></p>
                  <draggable v-model="exportFields" group="fields" class="drag-area" item-key="name">
                    <template #item="{element}">
                      <div class="drag-item">{{ element }}</div>
                    </template>
                  </draggable>
                </div>
                <div class="col">
                  <h6>Diese Felder nicht exportieren:</h6>
                  <p><small>Hier können Sie Elemente ablegen, die ignoriert werden sollen:</small></p>
                  <draggable v-model="ignoredFields" group="fields" class="drag-area" item-key="name">
                    <template #item="{element}">
                      <div class="drag-item">{{ element }}</div>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import Mail from "@/components/Mail.vue";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  name: "reportSelected",
  data() {
    return {
      batchMailWindow: false,
      fields: [],
      exportFields: [],
      ignoredFields: []
    };
  },
  computed: {
    ...mapGetters(['modalsList',]),
  },
  components: {Mail, draggable},
  methods: {
    getExportableFields() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "config/exportableFields", {headers}).then((r) => {
        this.fields = r.data.result.data;
        const routeName = this.$route.name;
        // Set exportFields based on the route name
        if (routeName === 'customers') {
          this.exportFields = this.fields.customers;
        } else if (routeName === 'Documents') {
          this.exportFields = this.fields.documents;
        } else if (routeName === 'Projects') {
          this.exportFields = this.fields.documents;
        }
        this.ignoredFields = [];
      });
    },
    exportData(listName, exportFields) {
      this.$store.dispatch('reportSelected', { listName, exportFields });
    },
  },
  mounted() {
    this.getExportableFields();
  }
}
</script>
<style scoped>
.drag-area {
  border: 1px solid #ccc;
  min-height: 200px;
  list-style-type: none;
  padding: 10px;
}

.drag-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  background-color: #f8f8f8;
}
</style>
